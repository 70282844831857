import axios from "axios";
// const link = "http://localhost:8080";

// pred-prod;
// const link = "https://kris-bang.alwaysdata.net/api-inscription-online-gta";

//prod
const link =
  "https://inscription-online.alwaysdata.net/api-inscription-online-gta-93";

const URL = link + "/Api";

const instance = axios.create({
  baseURL: URL,
});

if (localStorage.getItem("token") && localStorage.getItem("token") != "") {
  instance.defaults.headers.common["Authorization"] = `${localStorage.getItem(
    "token"
  )}`;
}

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.config.url != "/user/refreshToken" &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      if (
        localStorage.getItem("refreshToken") &&
        localStorage.getItem("refreshToken") != ""
      ) {
        instance.defaults.headers.common[
          "Authorization"
        ] = `${localStorage.getItem("refreshToken")}`;
        // console.log("refreshToken");
        await instance
          .post("/user/refreshToken")
          .then((response) => {
            // TODO: mettre à jour l'accessToken dans le localStorage
            localStorage.setItem("refreshToken", response.data.accessToken);
            originalRequest.headers[
              "Authorization"
            ] = `${response.data.accessToken}`;
            instance.defaults.headers.common[
              "Authorization"
            ] = `${response.data.accessToken}`;
          })
          .catch((err) => {
            console.log(err.response.status);
            localStorage.setItem("refreshToken", null);
          });
        return instance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default {
  instance: instance,
  api_url: link,
};

// import axios from "axios"
import Api from "../../config/config.js";

export default {
  async delete(options) {
    try {
      let deleteUser = await Api.instance.post(`/user/delete`, options);
      // console.log("deleteUser",deleteUser)
      return deleteUser.data.delete;
    } catch (error) {
      console.log(error);
    }
  },
  async updateMDP(options) {
    try {
      let updatetUserMDP = await Api.instance.post(`/user/update-mdp`, options);
      // console.log("updatetUserMDP",updatetUserMDP)
      return updatetUserMDP.data.update;
    } catch (error) {
      console.log(error);
    }
  },
  async update(options) {
    try {
      let updatetUser = await Api.instance.post(`/user/update`, options);
      // console.log("updatetUser",updatetUser)
      return updatetUser.data.update;
    } catch (error) {
      console.log(error);
    }
  },
  async post(options) {
    try {
      let postUser = await Api.instance.post(`/user/generate`, options);
      // console.log("postUser",postUser)
      return postUser.data.create;
    } catch (error) {
      console.log(error);
    }
  },
  async getAll(options) {
    try {
      let getUserAll = await Api.instance.get(`/user/getAll`, {
        params: options,
      });
      // console.log("getUserAlll",getUserAll)
      return getUserAll.data;
    } catch (error) {
      console.log(error);
    }
  },
  async get(options) {
    try {
      let getUser = await Api.instance.get(`/user/get`, { params: options });
      // console.log("getUser",getUser)
      return getUser.data;
    } catch (error) {
      console.log(error);
    }
  },
  async getCheckToken(options) {
    try {
      let checkToken = await Api.instance.get(`/user/checkToken`, {
        params: options,
      });
      // console.log("checkToken",checkToken)
      return checkToken.data;
    } catch (error) {
      console.log(error);
    }
  },
  // async get(options){
  //     try {
  //         let getUser = await Api.instance.get(`${config.api_url}/user/get`,{params:options})
  //         console.log("getUser",getUser)
  //         return getUser.data
  //     } catch (error) {
  //         console.log(error)
  //     }
  // },
};
